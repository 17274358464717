import * as React from 'react'
import Layout from '../components/Layout'

export default function Contacto() {
  return (
    <Layout>
      {/* Header */}
      <div className="">
        <div className="py-8 lg:py-14">
          <div className="relative z-10 mx-auto max-w-7xl pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-warm-gray-900 text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              Contactanos
            </h1>
            <p className="text-warm-gray-500 mt-6 max-w-3xl text-sm sm:text-base">
              Ofrecemos atención personalizada, como también envíos a todo el
              país en tiempo y forma. Contamos con depósitos en Caseros, CABA y
              en el norte de la Provincia de Buenos Aires, en la ciudad de
              Colón.
            </p>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 pb-10 sm:px-6 lg:px-8">
        <div className="shadow-xl">
          {/* Contact form */}
          <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <h3 className="text-lg font-medium text-gray-900">
              Enviar mensaje
            </h3>
            <form
              action="https://getform.io/f/337696d2-b482-4415-9c66-93e2aae14324"
              method="POST"
              className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Nombre
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    required
                    autoComplete="given-name"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Apellido
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    required
                    autoComplete="family-name"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Teléfono
                  </label>
                  <span id="phone-optional" className="text-sm text-gray-500">
                    Opcional
                  </span>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                    aria-describedby="phone-optional"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium text-gray-900"
                >
                  Título
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    required
                    className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Mensaje
                  </label>
                </div>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    required
                    rows={4}
                    className="block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-slate-500 focus:ring-slate-500"
                    aria-describedby="message-max"
                    defaultValue={''}
                  />
                </div>
              </div>
              <div className="sm:col-span-2 sm:flex sm:justify-end">
                <button
                  type="submit"
                  className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-sky-900 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:w-auto"
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
